import { useStaticQuery, graphql } from 'gatsby';

const uspsHook = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulUsp (
        filter: {
          description: {
            ne: null
          }
        }
        limit: 4
      ) {
        edges {
          node {
            label
            description
            icon {
              file {
                url
              }
            }
          }
        }
      }
    }
  `);

  const usps = data.allContentfulUsp.edges.map(edge => ({
    title: edge.node.label,
    description: edge.node.description,
    iconUrl: edge.node.icon.file.url,
  }));

  return usps;
};

export default uspsHook;
