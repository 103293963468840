import React from 'react';
import { navigate, graphql } from 'gatsby';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import uspsHook from '../hooks/uspsHook';
import Layout from '../components/layout';
import Section from '../components/Section/Section';
import SectionContent from '../components/SectionContent/SectionContent';
import SectionLabel from '../components/SectionLabel/SectionLabel';
import FeaturedPros from '../components/FeaturedPros/FeaturedPros';
import Button from '../components/Button/Button';
import SEO from '../components/seo';

export const query = graphql`
  query {
    contentfulContentPage (
      id: {
        eq: "90cef9d1-686d-5b46-a4ac-19ab52cdcb6f",
      },
    ) {
      id
      title
      content {
        json
      }
    }
  }
`;

const Materials = ({ data }) => {
  const { contentfulContentPage } = data;
  const page = {
    title: contentfulContentPage.title,
    content: contentfulContentPage.content.json,
  };

  const Bold = ({ children }) => (<span className="bold">{children}</span>);
  const Text = ({ children }) => (<p className="align-center">{children}</p>);

  const options = {
    renderMark: {
      [MARKS.BOLD]: text => (<Bold>{text}</Bold>),
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (<Text>{children}</Text>),
    },
  };

  const usps = uspsHook();

  return (
    <Layout>
      <SEO title={page.title} />
      <Section>
        <SectionContent centered>
          <h2>{page.title}</h2>
          {documentToReactComponents(page.content, options)}
        </SectionContent>
        <SectionContent centered>
          <SectionLabel label="Get a quote" />
          <h2>
            Talk to us about
            <br />
            your next project
          </h2>
          <Button onClick={() => navigate('/#contact')} label="Get in touch" cta />
        </SectionContent>
      </Section>

      <Section theme="dark">
        <FeaturedPros
          pros={usps}
        />
      </Section>
    </Layout>
  );
};

export default Materials;
