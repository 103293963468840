import React from 'react';
import PropTypes from 'prop-types';
import styles from './Button.module.scss';

import ctaIcon from '../../images/icons/Timberland-cta.svg';

const Button = ({
  type,
  icon,
  cta,
  label,
  loading,
  disabled,
  wide,
  onClick,
}) => (
  <button
    type={type}
    className={[
      styles.button,
      icon && styles.withIcon,
      cta && styles.withCta,
      disabled && styles.disabled,
      wide && styles.wide,
    ].join(' ')}
    disabled={disabled || loading}
    onClick={onClick}
  >
    {icon && <div className={styles.icon}>{icon}</div>}
    {label && <div className={styles.label}>{label}</div>}
    {wide && <div className={styles.wide}>{wide}</div>}
    {cta && <div className={styles.cta}><img src={ctaIcon} alt="Action" /></div>}
  </button>
);


Button.propTypes = {
  type: PropTypes.string,
  icon: PropTypes.element,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  type: 'button',
  icon: null,
  cta: false,
  disabled: false,
  onClick: null,
};

export default Button;
